// data.js
const studentData = [
  {
    id: 1,
    name: 'GOHIL JITENDRASINH DILIPSINH',
    course: 'ENVIRONMENT HEALTH AND SAFETY',
    batch: "3",
    enrollment_no: "CIFSE00UN3088",
    subjects: [
      { name: 'Occupational Health & Industrial Hygiene', marks: 76 },
      { name: 'Environment Safety Management', marks: 65 },
      { name: 'Psychology Ergonomics & Accident', marks: 62 },
      { name: 'Health, Safety & Environment Legislation', marks: 78 },
      { name: 'Risk Management & Permit To Work System', marks: 84 },
      { name: 'Safety In Powerplant', marks: 87 },
      { name: 'Practical 1', marks: 67 },
      { name: 'Practical 2', marks: 77 },
    ],
  },
  {
    id: 2,
    name: 'VALA AVINASH BHAGVANBHAI',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN7013",
    subjects: [
      { name: 'F.E.S.C', marks: 82 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 85 },
      { name: 'F.F.F.I', marks: 87 },
      { name: 'S.F.H', marks: 72 },
      { name: 'R.E.M', marks: 89 },
      { name: 'F.A.P', marks: 70 },
      { name: 'S.M', marks: 83 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 85 },
      { name: 'Practical 3', marks: 87 },
    ],
  },
  {
    id: 3,
    name: 'ADITYA DILIPBHAI DODIYA',
    course: 'FIRE MAN',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN7001",
    subjects: [
      { name: 'F.E.S.C', marks: 88 },
      { name: 'F.F.F.I', marks: 78 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 91 },
      { name: 'F.A.P', marks: 85 },
      { name: 'Safety In Powerplant', marks: 87 },
      { name: 'Practical 1', marks: 68 },
      { name: 'Practical 2', marks: 78 },
      { name: 'Practical 3', marks: 67 },
    ],
  },
  {
    id: 4,
    name: 'PARTH HARIBHAI PARMAR',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN7003",
    subjects: [
      { name: 'F.E.S.C', marks: 70 },
      { name: 'S.O.A', marks: 85 },
      { name: 'S.B.C', marks: 72 },
      { name: 'F.F.F.I', marks: 79 },
      { name: 'S.F.H', marks: 78 },
      { name: 'R.E.M', marks: 74 },
      { name: 'F.A.P', marks: 69 },
      { name: 'S.M', marks: 79 },
      { name: 'Practical 1', marks: 75 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 69 },
    ],
  },
  {
    id: 5,
    name: 'SATYARAJSINH DILIPSINH JADEJA',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN7009",
    subjects: [
      { name: 'F.E.S.C', marks: 90 },
      { name: 'S.O.A', marks: 77 },
      { name: 'S.B.C', marks: 84 },
      { name: 'F.F.F.I', marks: 91 },
      { name: 'S.F.H', marks: 73 },
      { name: 'R.E.M', marks: 78 },
      { name: 'F.A.P', marks: 84 },
      { name: 'S.M', marks: 86 },
      { name: 'Practical 1', marks: 79 },
      { name: 'Practical 2', marks: 73 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 6,
    name: 'DEVRAJ BHIMASHIBHAI BALIA',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN1012",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 7,
    name: 'HARPALSINH JAGMALSINH DODIYA',
    course: 'D.F.S.E',
    batch: "MMXX",
    enrollment_no: "CIFSE00UN1009",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 8,
    name: 'MAHIPATSINH JORUBHAI DODIYA',
    course: 'D.F.S.E',
    batch: "MMXX",
    enrollment_no: "CIFSE00UN1010",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 9,
    name: 'VIJAY DEVRAJBHAI JESAR',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN1011",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 10,
    name: 'JAGDISH KARABHAI SHINHORA',
    course: 'D.F.S.E',
    batch: "MMXX",
    enrollment_no: "CIFSE00UN8098",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 11,
    name: 'JAYPALSINH RANJITSINH MASANI',
    course: 'D.F.S.E',
    batch: "MMXX",
    enrollment_no: "CIFSE00UN7801",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 12,
    name: 'VIVEKBHAI LAXMANBHAI PADAYA',
    course: 'D.F.S.E',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN7834",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 13,
    name: 'ASHISHKUMAR NARANBHAI BARAD',
    course: 'D.F.S.E',
    batch: "MMXXIV",
    enrollment_no: "CIFSE00UN6980",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 14,
    name: 'SHAHIDHUSAIN SALIMMOHAMMAD THAKOR',
    course: 'D.F.S.E.',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN9007",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 15,
    name: 'VINOD PANCHABHAI SHIYAL',
    course: 'D.F.S.E.',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN6877",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 16,
    name: 'PRADEEP KUMAR OMVIR SINGH',
    course: 'D.F.S.E.',
    batch: "MMXXII",
    enrollment_no: "CIFSE00UN9032",
    subjects: [
      { name: 'F.E.S.C', marks: 76 },
      { name: 'S.O.A', marks: 70 },
      { name: 'S.B.C', marks: 68 },
      { name: 'F.F.F.I', marks: 84 },
      { name: 'S.F.H', marks: 73 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 79 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 79 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 17,
    name: 'ANIRUDH MANSINGHBHAI JADAV',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1401",
    subjects: [
      { name: 'F.E.S.C', marks: 80 },
      { name: 'S.O.A', marks: 84 },
      { name: 'S.B.C', marks: 84 },
      { name: 'F.F.F.I', marks: 86 },
      { name: 'S.F.H', marks: 79 },
      { name: 'R.E.M', marks: 78 },
      { name: 'F.A.P', marks: 81 },
      { name: 'S.M', marks: 88 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 18,
    name: 'ATUL MANUBHAI VAGHELA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1402",
    subjects: [
      { name: 'F.E.S.C', marks: 79 },
      { name: 'S.O.A', marks: 85 },
      { name: 'S.B.C', marks: 82 },
      { name: 'F.F.F.I', marks: 92 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 78 },
      { name: 'F.A.P', marks: 80 },
      { name: 'S.M', marks: 87 },
      { name: 'Practical 1', marks: 95 },
      { name: 'Practical 2', marks: 95 },
      { name: 'Practical 3', marks: 95 },
    ],
  },
  {
    id: 19,
    name: 'DHAVALKUMAR BABUBHAI PARMAR',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1422",
    subjects: [
      { name: 'F.E.S.C', marks: 77 },
      { name: 'S.O.A', marks: 75 },
      { name: 'S.B.C', marks: 68 },
      { name: 'F.F.F.I', marks: 65 },
      { name: 'S.F.H', marks: 69 },
      { name: 'R.E.M', marks: 82 },
      { name: 'F.A.P', marks: 65 },
      { name: 'S.M', marks: 82 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 70 },
      { name: 'Practical 3', marks: 65 },
    ],
  },
  {
    id: 20,
    name: 'DIGVIJAY MANSINGBHAI CHAUHAN',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1403",
    subjects: [
      { name: 'F.E.S.C', marks: 60 },
      { name: 'S.O.A', marks: 62 },
      { name: 'S.B.C', marks: 58 },
      { name: 'F.F.F.I', marks: 68 },
      { name: 'S.F.H', marks: 70 },
      { name: 'R.E.M', marks: 72 },
      { name: 'F.A.P', marks: 56 },
      { name: 'S.M', marks: 53 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 75 },
    ],
  },
  {
    id: 22,
    name: 'DIVYESH HARIBHAI CHAUHAN',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1404",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 85 },
      { name: 'S.B.C', marks: 84 },
      { name: 'F.F.F.I', marks: 89 },
      { name: 'S.F.H', marks: 70 },
      { name: 'R.E.M', marks: 85 },
      { name: 'F.A.P', marks: 88 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 92 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 23,
    name: 'HARSH RAMBHAI VALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1405",
    subjects: [
      { name: 'F.E.S.C', marks: 86 },
      { name: 'S.O.A', marks: 80 },
      { name: 'S.B.C', marks: 79 },
      { name: 'F.F.F.I', marks: 76 },
      { name: 'S.F.H', marks: 87 },
      { name: 'R.E.M', marks: 90 },
      { name: 'F.A.P', marks: 85 },
      { name: 'S.M', marks: 76 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 75 },
    ],
  },
  {
    id: 24,
    name: 'HITESH BABUBHAI CHAUHAN',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1423",
    subjects: [
      { name: 'F.E.S.C', marks: 50 },
      { name: 'S.O.A', marks: 58 },
      { name: 'S.B.C', marks: 67 },
      { name: 'F.F.F.I', marks: 62 },
      { name: 'S.F.H', marks: 52 },
      { name: 'R.E.M', marks: 75 },
      { name: 'F.A.P', marks: 58 },
      { name: 'S.M', marks: 67 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 70 },
      { name: 'Practical 3', marks: 64 },
    ],
  },
  {
    id: 25,
    name: 'JAYDIPSINH MANHARSINH ZALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1406",
    subjects: [
      { name: 'F.E.S.C', marks: 80 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 92 },
      { name: 'F.F.F.I', marks: 95 },
      { name: 'S.F.H', marks: 78 },
      { name: 'R.E.M', marks: 89 },
      { name: 'F.A.P', marks: 80 },
      { name: 'S.M', marks: 76 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 85 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 26,
    name: 'JIGNESHBHAI RAMANBHAI AMLIYAR',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1407",
    subjects: [
      { name: 'F.E.S.C', marks: 89 },
      { name: 'S.O.A', marks: 87 },
      { name: 'S.B.C', marks: 85 },
      { name: 'F.F.F.I', marks: 81 },
      { name: 'S.F.H', marks: 69 },
      { name: 'R.E.M', marks: 78 },
      { name: 'F.A.P', marks: 76 },
      { name: 'S.M', marks: 80 },
      { name: 'Practical 1', marks: 79 },
      { name: 'Practical 2', marks: 75 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 28,
    name: 'MAHESH DHIRUBHAI VAGHELA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1408",
    subjects: [
      { name: 'F.E.S.C', marks: 89 },
      { name: 'S.O.A', marks: 88 },
      { name: 'S.B.C', marks: 95 },
      { name: 'F.F.F.I', marks: 96 },
      { name: 'S.F.H', marks: 92 },
      { name: 'R.E.M', marks: 87 },
      { name: 'F.A.P', marks: 79 },
      { name: 'S.M', marks: 80 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 29,
    name: 'PARTH RANVIRBHAI JADAV',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1410",
    subjects: [
      { name: 'F.E.S.C', marks: 82 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 80 },
      { name: 'F.F.F.I', marks: 85 },
      { name: 'S.F.H', marks: 88 },
      { name: 'R.E.M', marks: 81 },
      { name: 'F.A.P', marks: 89 },
      { name: 'S.M', marks: 87 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 30,
    name: 'JADAV PIYUSHBHAI JASUBHAI',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1432",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 65 },
      { name: 'S.B.C', marks: 69 },
      { name: 'F.F.F.I', marks: 65 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 92 },
      { name: 'F.A.P', marks: 77 },
      { name: 'S.M', marks: 68 },
      { name: 'Practical 1', marks: 85 },
      { name: 'Practical 2', marks: 85 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 30,
    name: 'PRATIK RAJESHBHAI BARIYA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1413",
    subjects: [
      { name: 'F.E.S.C', marks: 90 },
      { name: 'S.O.A', marks: 95 },
      { name: 'S.B.C', marks: 97 },
      { name: 'F.F.F.I', marks: 93 },
      { name: 'S.F.H', marks: 99 },
      { name: 'R.E.M', marks: 89 },
      { name: 'F.A.P', marks: 80 },
      { name: 'S.M', marks: 86 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 31,
    name: 'PRATIK NAGINBHAI JADAV',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1412",
    subjects: [
      { name: 'F.E.S.C', marks: 87 },
      { name: 'S.O.A', marks: 80 },
      { name: 'S.B.C', marks: 78 },
      { name: 'F.F.F.I', marks: 77 },
      { name: 'S.F.H', marks: 65 },
      { name: 'R.E.M', marks: 68 },
      { name: 'F.A.P', marks: 62 },
      { name: 'S.M', marks: 58 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 85 },
    ],
  },
  {
    id: 32,
    name: 'CHAUDHARY PRAVIN KALUBHAI',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1428",
    subjects: [
      { name: 'F.E.S.C', marks: 80 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 75 },
      { name: 'F.F.F.I', marks: 72 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 67 },
      { name: 'F.A.P', marks: 89 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 70 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 85 },
    ],
  },
  // {
  //   id: 33,
  //   name: 'RAJKUMAR GOVINDBHAI CHAUHAN',
  //   course: 'D.F.S.E.',
  //   batch: "MMXXIV",
  //   enrollment_no: "DFSE00UN1415",
  //   subjects: [
  //     { name: 'F.E.S.C', marks: 75 },
  //     { name: 'S.O.A', marks: 69 },
  //     { name: 'S.B.C', marks: 70 },
  //     { name: 'F.F.F.I', marks: 89 },
  //     { name: 'S.F.H', marks: 55 },
  //     { name: 'R.E.M', marks: 66 },
  //     { name: 'F.A.P', marks: 64 },
  //     { name: 'S.M', marks: 72 },
  //     { name: 'Practical 1', marks: 80 },
  //     { name: 'Practical 2', marks: 80 },
  //     { name: 'Practical 3', marks: 85 },
  //   ],
  // },
  {
    id: 34,
    name: 'RAJ GANDABHAI VALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1414",
    subjects: [
      { name: 'F.E.S.C', marks: 84 },
      { name: 'S.O.A', marks: 88 },
      { name: 'S.B.C', marks: 90 },
      { name: 'F.F.F.I', marks: 92 },
      { name: 'S.F.H', marks: 96 },
      { name: 'R.E.M', marks: 94 },
      { name: 'F.A.P', marks: 89 },
      { name: 'S.M', marks: 82 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 35,
    name: 'SAGAR RAMESHBHAI VALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1417",
    subjects: [
      { name: 'F.E.S.C', marks: 72 },
      { name: 'S.O.A', marks: 62 },
      { name: 'S.B.C', marks: 60 },
      { name: 'F.F.F.I', marks: 58 },
      { name: 'S.F.H', marks: 78 },
      { name: 'R.E.M', marks: 56 },
      { name: 'F.A.P', marks: 72 },
      { name: 'S.M', marks: 77 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 92 },
      { name: 'Practical 3', marks: 83 },
    ],
  },
  {
    id: 36,
    name: 'SAGAR DILIPBHAI VALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1416",
    subjects: [
      { name: 'F.E.S.C', marks: 81 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 87 },
      { name: 'F.F.F.I', marks: 77 },
      { name: 'S.F.H', marks: 57 },
      { name: 'R.E.M', marks: 67 },
      { name: 'F.A.P', marks: 84 },
      { name: 'S.M', marks: 76 },
      { name: 'Practical 1', marks: 70 },
      { name: 'Practical 2', marks: 84 },
      { name: 'Practical 3', marks: 82 },
    ],
  },
  {
    id: 37,
    name: 'SATISH NOGHANBHAI CHAUHAN',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1418",
    subjects: [
      { name: 'F.E.S.C', marks: 78 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 85 },
      { name: 'F.F.F.I', marks: 76 },
      { name: 'S.F.H', marks: 80 },
      { name: 'R.E.M', marks: 93 },
      { name: 'F.A.P', marks: 79 },
      { name: 'S.M', marks: 86 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 38,
    name: 'SUNIL OGHADBHAI SOLANKI',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1419",
    subjects: [
      { name: 'F.E.S.C', marks: 87 },
      { name: 'S.O.A', marks: 89 },
      { name: 'S.B.C', marks: 92 },
      { name: 'F.F.F.I', marks: 78 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 90 },
      { name: 'F.A.P', marks: 95 },
      { name: 'S.M', marks: 98 },
      { name: 'Practical 1', marks: 91 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 85 },
    ],
  },
  {
    id: 39,
    name: 'VISHAL BHARATBHAI VALA',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1421",
    subjects: [
      { name: 'F.E.S.C', marks: 70 },
      { name: 'S.O.A', marks: 68 },
      { name: 'S.B.C', marks: 65 },
      { name: 'F.F.F.I', marks: 62 },
      { name: 'S.F.H', marks: 59 },
      { name: 'R.E.M', marks: 80 },
      { name: 'F.A.P', marks: 82 },
      { name: 'S.M', marks: 79 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 78 },
      { name: 'Practical 3', marks: 75 },
    ],
  },
  {
    id: 40,
    name: 'GOHIL RAVIRAJSINH SUJANSINH',
    course: 'D.F.S.E.',
    batch: "MMXXIII",
    enrollment_no: "DFSE00UN1490",
    subjects: [
      { name: 'F.E.S.C', marks: 75 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 89 },
      { name: 'F.F.F.I', marks: 80 },
      { name: 'S.F.H', marks: 78 },
      { name: 'R.E.M', marks: 70 },
      { name: 'F.A.P', marks: 90 },
      { name: 'S.M', marks: 89 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 41,
    name: 'SODHA YASHRAJSINH MAHENDRASINH',
    course: 'D.F.S.E.',
    batch: "MMXXIII",
    enrollment_no: "DFSE00UN1491",
    subjects: [
      { name: 'F.E.S.C', marks: 90 },
      { name: 'S.O.A', marks: 91 },
      { name: 'S.B.C', marks: 89 },
      { name: 'F.F.F.I', marks: 96 },
      { name: 'S.F.H', marks: 91 },
      { name: 'R.E.M', marks: 90 },
      { name: 'F.A.P', marks: 89 },
      { name: 'S.M', marks: 80 },
      { name: 'Practical 1', marks: 90 },
      { name: 'Practical 2', marks: 90 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 42,
    name: 'GOHIL HASTDIPSINH ANIRUDHSINH',
    course: 'D.F.S.E.',
    batch: "MMXXIII",
    enrollment_no: "DFSE00UN1492",
    subjects: [
      { name: 'F.E.S.C', marks: 80 },
      { name: 'S.O.A', marks: 79 },
      { name: 'S.B.C', marks: 77 },
      { name: 'F.F.F.I', marks: 70 },
      { name: 'S.F.H', marks: 69 },
      { name: 'R.E.M', marks: 82 },
      { name: 'F.A.P', marks: 86 },
      { name: 'S.M', marks: 78 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 75 },
      { name: 'Practical 3', marks: 90 },
    ],
  },
  {
    id: 43,
    name: 'JAMBUKIYA RAJESHKUMAR HARESHBHAI',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1493",
    subjects: [
      { name: 'F.E.S.C', marks: 79 },
      { name: 'S.O.A', marks: 68 },
      { name: 'S.B.C', marks: 70 },
      { name: 'F.F.F.I', marks: 80 },
      { name: 'S.F.H', marks: 86 },
      { name: 'R.E.M', marks: 92 },
      { name: 'F.A.P', marks: 80 },
      { name: 'S.M', marks: 56 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 44,
    name: 'KALIYA SURESHBHAI MANEKBHAI',
    course: 'D.F.S.E.',
    batch: "MMXXIV",
    enrollment_no: "DFSE00UN1494",
    subjects: [
      { name: 'F.E.S.C', marks: 70 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 67 },
      { name: 'F.F.F.I', marks: 69 },
      { name: 'S.F.H', marks: 80 },
      { name: 'R.E.M', marks: 92 },
      { name: 'F.A.P', marks: 89 },
      { name: 'S.M', marks: 85 },
      { name: 'Practical 1', marks: 80 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 80 },
    ],
  },
  {
    id: 45,
    name: 'RAKESH MANDANLAL PANOLA',
    course: 'D.F.S.E.',
    batch: "MMXXI",
    enrollment_no: "CIFSE00UN70191",
    subjects: [
      { name: 'F.E.S.C', marks: 69 },
      { name: 'S.O.A', marks: 72 },
      { name: 'S.B.C', marks: 85 },
      { name: 'F.F.F.I', marks: 68 },
      { name: 'S.F.H', marks: 72 },
      { name: 'R.E.M', marks: 78 },
      { name: 'F.A.P', marks: 68 },
      { name: 'S.M', marks: 77 },
      { name: 'Practical 1', marks: 70 },
      { name: 'Practical 2', marks: 82 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 46,
    name: 'SAGAR DHARMARAJ MAHENDRABHAI',
    course: 'D.F.S.E.',
    batch: "XII",
    enrollment_no: "DFSE/2056UN2021",
    subjects: [
      { name: 'F.E.S.C', marks: 88 },
      { name: 'S.O.A', marks: 82 },
      { name: 'S.B.C', marks: 78 },
      { name: 'F.F.F.I', marks: 62 },
      { name: 'S.F.H', marks: 77 },
      { name: 'R.E.M', marks: 69 },
      { name: 'F.A.P', marks: 87 },
      { name: 'S.M', marks: 59 },
      { name: 'Practical 1', marks: 65 },
      { name: 'Practical 2', marks: 80 },
      { name: 'Practical 3', marks: 72 },
    ],
  },
  {
    id: 47,
    name: 'SOLANKI VISHAVJITSINH BHARATSINH',
    course: 'D.F.S.E.',
    batch: "XII",
    enrollment_no: "DFSE/1023UN2021",
    subjects: [
      { name: 'F.E.S.C', marks: 71 },
      { name: 'S.O.A', marks: 68 },
      { name: 'S.B.C', marks: 74 },
      { name: 'F.F.F.I', marks: 82 },
      { name: 'S.F.H', marks: 63 },
      { name: 'R.E.M', marks: 58 },
      { name: 'F.A.P', marks: 78 },
      { name: 'S.M', marks: 86 },
      { name: 'Practical 1', marks: 55 },
      { name: 'Practical 2', marks: 70 },
      { name: 'Practical 3', marks: 66 },
    ],
  },
  {
    id: 48, 
    name: 'RATHOD VIJAYRAJSINH HATHISINH',
    course: 'D.F.S.E.',
    batch: "XII",
    enrollment_no: "DFSE/3079UN2021",
    subjects: [
      { name: 'F.E.S.C', marks: 67 },
      { name: 'S.O.A', marks: 78 },
      { name: 'S.B.C', marks: 59 },
      { name: 'F.F.F.I', marks: 66 },
      { name: 'S.F.H', marks: 89 },
      { name: 'R.E.M', marks: 83 },
      { name: 'F.A.P', marks: 74 },
      { name: 'S.M', marks: 64 },
      { name: 'Practical 1', marks: 55 },
      { name: 'Practical 2', marks: 63 },
      { name: 'Practical 3', marks: 76 },
    ],
  },
  {
    id: 49, 
    name: 'PARMAR PARTH HARI BHAI',
    course: 'P.D.I.S',
    batch: "III 2022/2023",
    enrollment_no: "CIFSE00UN1057",
    subjects: [
      { name: 'S.M (SEM-1)', marks: 75, total: 80 },
      { name: 'S.E-1 (SEM-1)', marks: 69, total: 80 },
      { name: 'S.C.I (SEM-1)', marks: 72, total: 80 },
      { name: 'S.E-II (SEM-1)', marks: 58, total: 80 },
      { name: 'CASE STUDY & SEMINAR (SEM-1)', marks: 65, total: 80 },
      { name: 'I.V. (SEM-1)', marks: 62, total: 80 },
      { name: 'INTERNSHIP-I (SEM-1)', marks: 78, total: 80 },
      { name: 'H.R.D.C.T (SEM-2)', marks: 69, total: 80 },
      { name: 'I.H.H (SEM-2)', marks: 73, total: 80 },
      { name: 'H.L.A.S.P (SEM-2)', marks: 84, total: 80 },
      { name: 'P.E. (SEM-2)', marks: 67, total: 80 },
      { name: 'S.A.S (SEM-2)', marks: 56, total: 80 },
      { name: 'I.V. (SEM-2)', marks: 59, total: 80 },
      { name: 'INTERNSHIP-II (SEM-2)', marks: 77, total: 80 },
    ],
  },
];

export default studentData;