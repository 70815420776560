export const ROUTES = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  RegularCourse: "/regular-course",
  DistanceCourse: "/distance-course",
  Gallery: "/gallery",
  Result: "/student-result",
  // bscfire: "/bsc-in-fire-safety-and-hazard-management",
};

export const InnerPageRoutes = {
  //Regular Course Innerpageroutes
  bscFire: `${ROUTES.RegularCourse}/bsc-fire-safety-and-hazard-management`,

  diplomaFireService: `${ROUTES.RegularCourse}/diploma-fire-service-engineering`,

  adis12th: `${ROUTES.RegularCourse}/advance-diploma-in-industrial-safety`,

  dfse: `${ROUTES.RegularCourse}/diploma-fire-and-safety`,

  adis: `${ROUTES.RegularCourse}/advance-diploma-industrial-safety-security-management`,

  cfse: `${ROUTES.RegularCourse}/certificate-fire-safety-engineering`,

  mbaFireSafety: `${ROUTES.RegularCourse}/mba-fire-safety-management`,

  foc: `${ROUTES.RegularCourse}/fire-officer-course`,

  pgdFiresafety: `${ROUTES.RegularCourse}/diploma-in-fire-safety`,

  mbaIndustrialSafety: `${ROUTES.RegularCourse}/mba-industrial-safety-management`,

  pgdIndustrialSafety: `${ROUTES.RegularCourse}/pg-diploma-industrial-safety`,

  soc: `${ROUTES.RegularCourse}/safety-officer-course`,

  //Distance Course Innerpageroutes

  adiehs: `${ROUTES.DistanceCourse}/advance-diploma-occupational-safety`,

  adfies: `${ROUTES.DistanceCourse}/advance-diploma-fire-industrial-safety-engineering`,

  dfsim: `${ROUTES.DistanceCourse}/diploma-fire-industrial-safety`,

  adies: `${ROUTES.DistanceCourse}/advance-diploma-industrial-safety-engineering`,

  ddm: `${ROUTES.DistanceCourse}/diploma-disaster-management`,

  dhsem: `${ROUTES.DistanceCourse}/diploma-health-safety-environment-management`,

  dfsm: `${ROUTES.DistanceCourse}/diploma-in-fire-safety-management`,

  pgfsm: `${ROUTES.DistanceCourse}/pg-diploma-fire-safety-management`,

  dism: `${ROUTES.DistanceCourse}/diploma-industrial-safety-management`,

  mba: `${ROUTES.DistanceCourse}/mba-safety-fire-management-programs`,

  pgism: `${ROUTES.DistanceCourse}/pg-diploma-industrial-safety-management`,

  pghse: `${ROUTES.DistanceCourse}/pg-diploma-health-safety-environment`,

  phdms: `${ROUTES.DistanceCourse}/pg-diploma-disaster-crisis`,
};
