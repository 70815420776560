import React from 'react'

function OurStory() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
        <>
            <section className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="about-content">
                                <span className="sub-title">
                                    <i className="flaticon-hashtag-symbol"></i>
                                    About Us
                                </span>
                                <h3>WELCOME TO CENTRAL INSTITUTE OF <span>FIRE & SAFETY ENGINEERING</span></h3>
                                <p className='text-justify'>Central Institute of Fire & Safety Engineering has been founded to produce skilled and trained professionals in the field
                                    of engineering and safety. Since last eight years,
                                    We have seen tremendous progress in the field of fire services & growth in fire technology development in entire India.
                                    Different type of industries, commerce, building, infrastructure etc. brings the necessity to efficiently and effectively
                                    channelize the manpower of the country. Fire, Safety, Health and Environment are prime issues and implied concerns for this new breed.
                                    This is the reason which places a premium on well trained young men and women possessing superior sills in these areas.
                                    It is to impart this relevant 4 education to the young men and women that the specialized educational _ programs are being offered by CIFSE.
                                    The educational programs are being offered by CIFSE are based on contemporary theory and practice to provide
                                    strong conceptual foundations to those aspiring rewarding careers and high position jobs in these new fields.
                                    CIFSE's track record of excellence is impeccable.
                                    The current requirements of Fire, Safety and Security education are shaped by the New World industrial order,
                                    economic, technological and legal factors.
                                    CIFSE is a professional Institute that seeks to better society by developing leaders in the world of practical affairs.
                                </p>
                                <p className='text-justify'>We create and deliver education and research to advance knowledge and practice of business community.
                                    Our goal is to become National and then an International leader in the chosen field of education widely
                                    recognized by the business and academic communities for our - Action-Oriented students who take an
                                    enterprise perspective and leads with determination, vision, judgment, integrity, and social responsibility.
                                    Outstanding student-centered teaching that results from faculty working together and being committed to the development
                                    of the students as the individual.
                                    Integrated, innovative and modular curriculum attuned to the lifelong learning needs of the current and future enterprises.
                                    Supportive and equitable learning climate characterized by engagement with the issues of diversity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h3>Vision</h3>
                            <p>To create awareness of “The quality of life protection” through fire safety training programs.
                                <br />
                                To induce lifelong learning knowledge skills,for social and personal development.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={process.env.PUBLIC_URL + "assets/images/about/400.jpg"} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={process.env.PUBLIC_URL + "assets/images/about/401.jpg"} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3>Mission
                            </h3>
                            <p>To establish as India's premier Institute of Fire & Safety Engineering.<br />
                                To pursue for attending and imparting the highest standard of teaching and training in the field of Fire & Safety Engineering.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h3>Scope
                            </h3>
                            <p>To contents of the course are specially designed and will be of great interest to this who wish to take up Fire Safety
                                professional as a career, also those whose experience is confined to a specific field of safety and who wish to broaden their
                                understanding of the industry and all new entrants to the field</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={process.env.PUBLIC_URL + "assets/images/about/408.jpg"} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={process.env.PUBLIC_URL + "assets/images/others/405.png"} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3>Features
                            </h3>
                            <ul className="about-list">
                                <li>
                                    Highly qualified, skilled and experienced staff having practical fire fighting knowledge.
                                </li>
                                <li>
                                    Regular exposure of students to various equipment's and appliances associated with fire.
                                </li>
                                <li>
                                    Vast and varied practical curriculum.
                                </li>
                                <li>
                                    Regular Technical visits to industries, organizations and places involving high fire risks in order to encourage and build-up the student’s fire fighting courageous knowledge.
                                </li>
                                <li>
                                    Practical attachment training in leading organizations.
                                </li>
                                <li>
                                    Fresh, friendly, energetic and interlinking environment between the staff and students.
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <section className="pricing-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h3>Objective
                            </h3>
                            <ul className="about-list">
                                <li>
                                    To develop high academic standards and quality of education for its programs so as to extend limitations beyond traditional classroom situations.
                                </li>
                                <li>
                                    To create through effective education, training and research, a renewable talent bank in varied disciplines.
                                </li>
                                <li>
                                    To assist in development of new methods and process of fire engineering services, skills and analytical techniques.
                                </li>
                                <li>
                                    To develop managerial/leadership abilities and analytical skills, training, communication with the community and interaction with government and statutory authorities.
                                </li>
                                <li>
                                    To promote placements in various industries / organizations.
                                </li>
                                <li>
                                    To create awaeness about Fire and precautions to avoid miss-happenings due to Fire.
                                </li>

                                <li>
                                    To expertise mankind in using machines and allied equipments more effectively during Fire, excess environmental pollution and hazards associated with them.
                                </li>
                                <li>
                                    To minimize loses of life and property due to Fire by producing skilled professionals who can standby firmly during calamities.
                                </li>
                                <li>
                                    To carve out trained professionals to fulfill the necessities of a fully fledged Safety and Environment Control Department, as this is now felt by emerging competitive environment industries and businesses.
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={process.env.PUBLIC_URL + "assets/images/about/406.jpg"} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="doctor-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title-warp">
                                <span className="sub-title">
                                    <i className="flaticon-hashtag-symbol"></i>
                                    Directors
                                </span>
                                <h2>Director Profile</h2>
                                <p>
                                    Youth Fire Stop is a program designed to prevent misuse of fire by youth in our community
                                    and to educate and intervene when endangerment involving fire occurs. Youth Fire Stop has
                                    been streamlined in order to make the program more effective.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-doctor">
                                <img src={process.env.PUBLIC_URL + "assets/images/main-photo/Sid.jpeg"} alt="image" />
                                <div className="doctor-content">
                                    <h3>
                                        Siddharth Dodiya
                                    </h3>
                                    <span><b>(Director)</b><br />B.Sc. Fire, M.Sc. Hazard Management, NEBOSH (UK), EHS, IOSH, PGDIS</span>
                                    <div className="share-link">
                                        <a href="https://www.facebook.com/" target="_blank"><i className='bx bxl-facebook'></i></a>
                                        <a href="https://twitter.com/?lang=en" target="_blank"><i
                                            className='bx bxl-twitter'></i></a>
                                        <a href="https://www.linkedin.com/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                                        <a href="https://www.instagram.com/" target="_blank"><i
                                            className='bx bxl-instagram'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="single-doctor">
                                <a href="dentist-details.html"><img src="assets/images/others/124.jpg" alt="image" /></a>
                                <div className="doctor-content">
                                    <h3>
                                        <a href="dentist-details.html">Dhara Dodiya</a>
                                    </h3>
                                    <span>CEO</span>
                                    <div className="share-link">
                                        <a href="https://www.facebook.com/" target="_blank"><i className='bx bxl-facebook'></i></a>
                                        <a href="https://twitter.com/?lang=en" target="_blank"><i
                                            className='bx bxl-twitter'></i></a>
                                        <a href="https://www.linkedin.com/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                                        <a href="https://www.instagram.com/" target="_blank"><i
                                            className='bx bxl-instagram'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}
export default OurStory;
