import React from 'react'

function Placements() {
    return (
        <>
            <div className="skin-care-partner-inner-box" id='partner_1'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-warp">
                                <span className="sub-title"># Our Placements Partner</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="skin-care-partner-area">
                        <div className="skin-care-partner-slides owl-carousel owl-theme">
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/05.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/01.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/02.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/03.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/04.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/05.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/06.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/07.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/08.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/09.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/10.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/11.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/12.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/13.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/14.png" alt="partner" />
                                </a>
                            </div>
                            <div className="skin-care-partner-card">
                                <a href="#">
                                    <img src="assets/images/placement/15.png" alt="partner" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Placements;